#hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  background: $dark-grey;
  font-weight: 400;
  color: $white-color;
  padding: 0rem 4rem;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;

  // Set position sticky so the jumbotron stays fixed while you scroll.
  //position: sticky;

  // If you want to set a background image on the hero section, uncomment these with your custom image

  /* background: url("/src/assets/[your-image].png");
  background-position: center;
  background-size: cover; */

  .hero-cta {
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }

    & a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}

.to-about-section i {
  color: $white-color;
  margin: 1rem auto $default-font-size;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in;
}

.down-on-hover {
  &:hover {
    margin-bottom: 4px;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.bounce {
  display: inline-block;
  animation: bounce 1s infinite ease-in-out;
}

#typewriter {
  font-size: 5.6rem;
  font-weight: 700;
  align-items: left;
  margin-bottom: 6rem;
  text-align: left;
  font-family: "Courier New";
  overflow: hidden;
  padding: 0rem;
  margin-bottom: 1rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &:after {
    content: "|";
    animation: blink 500ms linear infinite alternate;
  }

  @include respond(tab-port) {
    font-size: 3.6rem;
  }
  @include respond(phone) {
    font-size: 1.8rem;
  }
  @include respond(phone-xs) {
    font-size: 1.4rem;
  }
}

.var-highlight {
  color: #c0ad60;
}
.string-highlight {
  color: rgba(253, 149, 90, 0.8);
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
