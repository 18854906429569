html {
  font-size: 62.5%;

  &.sr .load-hidden {
    visibility: hidden;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  scroll-behavior: smooth;
  background-color: $dark-grey;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: $dark-grey;
}
::-webkit-scrollbar-thumb {
  background: $light-grey;
}
::-webkit-scrollbar-thumb:hover {
  background: $white-color;
}

.centered-on-mobile {
  @include respond(tab-land) {
    text-align: center;
  }
}
