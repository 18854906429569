/* Call to Action Button */
.cta-btn {
  display: inline-block;
  position: relative;
  padding: 0.8rem $default-font-size;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.4s;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
  }
}

.github-icon-stop-1 {
  stop-color: #8091d6;
  stop-opacity: 1;
  transition-duration: 0.2s;
}

.github-icon-stop-2 {
  stop-color: #70b4d8;
  stop-opacity: 1;
  transition-duration: 0.2s;
}

.cta-btn--hero:hover stop {
  stop-color: $dark-grey;
}

/* Hero Style */
.cta-btn--hero {
  margin-left: 0;
  margin-right: auto;

  @include supportColorForIE11();
  border-width: 2px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  border-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  -webkit-border-image-slice: 1;
  border-image-slice: 1;
  @include supportIE11() {
    color: $secondary-color !important;
    &:hover {
      color: $gradient-contrast-color !important;
    }
  }

  &::after {
    background-image: linear-gradient(
      135deg,
      $primary-color 0%,
      $secondary-color 100%
    );
  }

  &:hover {
    -webkit-text-fill-color: $gradient-contrast-color;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}

/* Resume Style */
.cta-btn--against-gradient {
  color: $gradient-contrast-color;
  border: 2px solid $gradient-contrast-color;

  &::after {
    background: $gradient-contrast-color;
  }

  &:hover {
    color: $white-color;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}

/* Arrow Button */
.up i {
  color: #272727;
}
